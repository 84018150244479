import * as React from 'react';
import { Layout } from '../../components/layout';
import * as Styles from '../../styles/pages/_login.module.scss';
import * as CommonStyles from '../../styles/global/_common.module.scss';
import { BackGround } from '../../components/background';
import {Link, useIntl} from "gatsby-plugin-react-intl"

const ProviderCallbackPage = ({ location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})

  return (
    <Layout location={location}>
      <BackGround>
        <section className={CommonStyles.wrapper}>
          <div className={CommonStyles.container}>
            <div id="users" className={Styles.users}>
              <h1>{t('users.callback.title')}</h1>
              <section>
                <div className={Styles.mg_b2}>
                  {t('users.callback.message')}<br/>
                </div>
              </section>
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export default ProviderCallbackPage;
